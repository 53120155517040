// IMPORTS
@import (reference) "../settings.less";

body[data-type="mainpage"] main {

    // first child after breadcrumb
    > div,
    > section {
        &:nth-child(2) {
            padding-top: 24px;
        }
    }
}

// MAIN BANNER
.main-banner-wrapper {
    .grid();
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 20px;

    .decal {
        pointer-events: none;
        display: none;
    }

    .main-banner:last-child,
    .side-banner:last-child {

        .decal {
            .grid();
        }
    }
}

.main-banner {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
    width: 100%;
    border-radius: 8px;
    position: relative;

    .swiper-wrapper,
    .swiper-slide,
    .banner-img-wrapper {
        width: 100%;
        aspect-ratio: ~"710/466";
    }

    --swiper-navigation-sides-offset: 0;

    .swiper-button-prev,
    .swiper-button-next {
        width: 20px;
        border: none !important;

        &:hover:after {
            color: @font-color;
        }
    }

    .swiper-button-prev {
        border-radius: 0 4px 4px 0;
    }

    .swiper-button-next {
        border-radius: 4px 0 0 4px;
    }
}

.side-banners-wrapper {
    display: contents;

    .side-banner {
        aspect-ratio: ~"345/223";

        img {
            border-radius: 8px;
        }
    }
}



.main-banner .swiper-slide,
.side-banner {
    position: relative;

    img {
        .img-crop();
    }

    &:after {
        content: '';
        position: absolute;
        inset: 0;
        background: linear-gradient(180.00deg, rgba(0, 0, 0, 0) 59.5%,rgba(0, 0, 0, 0.7) 100%);
        z-index: 1;
    }

    .banner-text {
        position: absolute;
        bottom: 24px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 20px;
        line-height: 1.2;
        text-transform: uppercase;
        color: white;
        width: 100%;
        text-align: center;
        padding: 0 8px;
        z-index: 2;
    }
}

.side-banner:after {
    border-radius: 8px;
}

// MAINPAGE PRODUCTS
.main-page-products-wrapper {
    position: relative;
    --swiper-navigation-sides-offset: -20px;
}